import HeaderTemplate from "../ui/HeaderTemplate.vue";
import { signOut } from "../../utils/auth";
import IntercomMixin from "../ui/IntercomMixin";
import mixins from "vue-typed-mixins";
export default mixins(IntercomMixin).extend({
    components: {
        HeaderTemplate: HeaderTemplate,
    },
    methods: {
        onSignOut: function () {
            return signOut(this.$store, this.$router, this.$apollo);
        },
    },
});
