var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    data: function () {
        return {};
    },
    apollo: {
        viewerAgent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        viewerAgent {\n          id\n          intercomId\n          emailAddress\n          contactPersonName\n        }\n      }\n    "], ["\n      {\n        viewerAgent {\n          id\n          intercomId\n          emailAddress\n          contactPersonName\n        }\n      }\n    "]))),
        viewerStudent: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        viewerStudent {\n          id\n          intercomId\n          emailAddress\n          fullName\n        }\n      }\n    "], ["\n      {\n        viewerStudent {\n          id\n          intercomId\n          emailAddress\n          fullName\n        }\n      }\n    "]))),
    },
    mounted: function () {
        if (this.viewerAgent) {
            this.$intercom.boot({
                user_id: this.viewerAgent.intercomId,
                name: this.viewerAgent.contactPersonName,
                email: this.viewerAgent.emailAddress,
            });
            return;
        }
        if (this.viewerStudent) {
            this.$intercom.boot({
                user_id: this.viewerStudent.intercomId,
                name: this.viewerStudent.fullName,
                email: this.viewerStudent.emailAddress,
            });
            return;
        }
        this.$intercom.boot();
    },
});
var templateObject_1, templateObject_2;
