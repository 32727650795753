import Vue from "vue";
import LanguageSelector from "./LanguageSelector.vue";
function isHtmlElement(target) {
    return "tagName" in target;
}
function isAnchor(target) {
    return isHtmlElement(target) && target.tagName.toLowerCase() === "a";
}
function isListItem(target) {
    return isHtmlElement(target) && target.tagName.toLowerCase() === "li";
}
export default Vue.extend({
    components: {
        LanguageSelector: LanguageSelector,
    },
    data: function () {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        onOpenMenu: function () {
            this.isMenuOpen = true;
        },
        onCloseMenu: function () {
            this.isMenuOpen = false;
        },
        onListClick: function (e) {
            var target = e.target;
            if (!target || !isHtmlElement(target)) {
                return;
            }
            if (isAnchor(target)) {
                this.onCloseMenu();
                return;
            }
            var role = target.attributes.getNamedItem("role");
            if (isListItem(target) && role && role.value === "option") {
                this.onCloseMenu();
            }
        },
    },
});
